:root {
  --background: #c3bbc3;
  --accent1: #bab4c3;
  --accent2: #b0acc3;
  --accent3: #a2a1c5;
  --accent4: #615e61;
  --text: #f5f5f5;
  --text-dark: #4a5759;
}

.app {
  background-color: var(--background);
}

.h1 {
  font-size: 3cpw !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-toggler {
  font-size: 1rem !important;
  background-color: var(--text);
}

.navbar {
  background: var(--text);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 0px;
  mix-blend-mode: lighten;
}

.invitation {
  margin-top: 1cqh;
  color: var(--text-dark);
}

.nav-link {
  padding-left: 16px;
  color: black !important;
  text-align: center;
}

.title {
  font-size: 12cqw;
  container-type: inline-size;
}

.uhrzeit {
  font-size: large !important;
  text-align: left;
}

.row {
  align-items: center;
  justify-content: space-evenly;
  margin: 0px !important;
}

.title {
  font-family: "TheSeasons";
}

.fancy-font,
.section-header {
  font-family: "Dancing Script" !important;
}

.beautifully-delicious {
  font-family: "BeautifullyDeliciousScript";
  font-size: 15cqw;
}

.col {
  text-align: center;
}

.slanted {
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%);
}

.double-slanted {
  clip-path: polygon(0 3%, 100% 0%, 100% 97%, 0 100%);
}

.section {
  padding-bottom: 30px;
  .section-header {
    font-size: 5cqh;
    color: var(--text-dark);
  }
}

.locations {
  background-color: var(--accent4);
  .section-header {
    color: var(--text);
  }
  .location {
    margin-top: 10px;
    margin-bottom: 10px;

    .location-details {
      padding: 15px;
      min-height: 40cqh;
      border-radius: 5px;
      background-position: center;
      background-size: cover;
      box-shadow: inset 0px 0px 0 2000px rgba(0, 0, 30, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.time-property {
  min-width: 100px;
  h5 {
    margin: 10px;
    font-size: clamp(1.25rem, 3cqw, 3cqw) !important;
  }
}

.landing-image {
  display: block;
  max-height: 90cqh;
  width: 100%;
  object-fit: contain;
  margin-top: 40px;
}

.carousel-caption {
  bottom: 2% !important;
  left: 70% !important;
}

.carousel-control-next,
.carousel-control-prev {
  mix-blend-mode: difference;
}

.gmap-button {
  margin-left: 3px;
  font-size: 1.3rem;
  color: var(--text) !important;
  background-color: unset !important;
  border-style: solid;
  border-color: var(--accent1) !important;
}

.overlay-header {
  display: inline;
  max-width: 70%;
}

.overlay-header,
.overlay-text {
  color: var(--text);
}

.rsvp {
  width: 100%;
  p {
    font-size: 2cqh;
    color: var(--text);
  }

  .mail {
    color: var(--text);
    background-color: rgba(97, 94, 97, 0.4);
    border-radius: 5px;
    padding: 3px;
    cursor: pointer;
  }
}

.ansprechpartner {
  background-color: var(--accent3);
  clip-path: polygon(0 0, 100% 3cqh, 100% 100%, 0% 100%);
  margin-top: -5cqh;
  padding-top: 5cqh;
}

.card {
  width: 45cqw;
  background-color: unset !important;
  border-radius: 5px !important;
  border: 1px solid var(--accent3) !important;
  margin: 15px !important;
  border-radius: 0.25rem;
  color: var(--text-dark);

  .card-title {
    font-size: 4cqh;
  }
  .card-subtitle {
    font-size: 3cqh;
  }
}

.card-img-top {
  clip-path: circle(38% at 50% 50%);
}

.card-body {
  background-color: var(--accent3);
}

.trauzeugen-row {
  flex-wrap: nowrap !important;
}
.icon {
  color: var(--text-dark);
}

@media screen and (width <= 768px) {
  .card {
    width: 90cqw;
  }

  .trauzeugen-row {
    flex-wrap: wrap !important;
  }
}

.react-parallax-bgimage {
  filter: blur(2px) brightness(0.7) !important;
}

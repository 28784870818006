.login {
  height: 100cqh;
  background-image: url("../../assets/home/home-hands.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (width >= 540px) {
  .login {
    border-style: solid;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 25px;
    border-right-width: 25px;
    border-color: var(--accent1);
  }
}

.password-col {
  flex-wrap: nowrap !important;
  padding-top: 20cqh;
  color: var(--text-dark);
  font-size: x-large;
}

.password-form {
  background-color: rgb(from var(--accent2) r g b / 75%);
  padding: 10px;
  border-radius: 5px;

  .wrong-pw {
    color: red;
    font-size: medium;
  }
}

.form-control {
  margin-right: 5px;
}

.pw-btn {
  background-color: var(--background) !important;
  border-color: lightgray !important;
  color: var(--text);
}
